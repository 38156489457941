import useOpenInAppMutation from '@/lib/mutations/openInApp';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import dialogQueryOptions from '@/lib/queries/dialog';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import profileQueryOptions from '@/lib/queries/profile';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';

export default function EmailToast({
  id,
  idUser,
  idInterlocutor,
}: {
  id: string;
  idUser: number;
  idInterlocutor: number;
}) {
  const qc = useQueryClient();

  const profileData = useQuery(profileQueryOptions(qc, idInterlocutor)).data;

  const dialogData = useQuery(
    dialogQueryOptions(qc, idUser, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(qc, idInterlocutor, idUser),
    enabled: !(dialogData && profileData),
  });

  const girlProfile = useQuery(girlsProfilesQueryOptions).data?.find(
    profile => profile.id === idUser
  );

  const sortedIds = [idUser, idInterlocutor].sort((a, b) => a - b);
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/mails/view/${sortedIds[0]}_${sortedIds[1]}`
  );

  if (!profileData || !girlProfile || !dialogData) return <div>Loading</div>;

  return (
    <button
      className='flex w-[300px] items-center overflow-hidden py-2'
      onClick={() => mutate(idUser, { onSuccess: () => toast.dismiss(id) })}
      disabled={isPending}
    >
      <div className='flex items-center gap-2 text-base font-semibold'>
        <div>
          <Avatar>
            <AvatarImage src={profileData.personal.avatar_large} />
            <AvatarFallback>{profileData.name}</AvatarFallback>
          </Avatar>
        </div>
        <div>
          <div className='flex flex-1 flex-col overflow-hidden'>
            <div className='flex'>
              <div className='w-[90px] max-w-[90px] truncate text-start'>
                {profileData.name}
              </div>
              <div className='w-[90px] max-w-[90px] truncate text-end'>
                {girlProfile.name}
              </div>
            </div>
          </div>
          <div className='flex justify-center gap-1 truncate text-sm'>
            <EnvelopeClosedIcon className='h-5 w-5' />
            <div>New letter!</div>
          </div>
        </div>
        <div>
          <Avatar>
            <AvatarImage src={girlProfile.personal.avatar_large} />
            <AvatarFallback>{girlProfile.name}</AvatarFallback>
          </Avatar>
        </div>
      </div>
    </button>
  );
}
