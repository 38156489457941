import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function calculateRegistrationDuration(dateCreated: string): string {
  const createdDate = new Date(dateCreated);
  const now = new Date();

  const timeDifference = now.getTime() - createdDate.getTime();
  const oneDay = 1000 * 60 * 60 * 24;

  if (timeDifference < oneDay) {
    return 'FIRST DAY';
  }

  const days = Math.floor(timeDifference / oneDay);

  if (days < 30) {
    return `${days}day${days > 1 ? 's' : ''}`;
  }

  const months =
    now.getMonth() -
    createdDate.getMonth() +
    (now.getFullYear() - createdDate.getFullYear()) * 12;

  const remainingDays = days % 30;

  if (months < 12) {
    return `${months}mon${months > 1 ? 's' : ''}${
      remainingDays > 0
        ? ` ${remainingDays}day${remainingDays > 1 ? 's' : ''}`
        : ''
    }`;
  }

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  return `${years}yr${years > 1 ? 's' : ''}${
    remainingMonths > 0
      ? ` ${remainingMonths}mon${remainingMonths > 1 ? 's' : ''}`
      : ''
  }`;
}

export function formatDetailedDate(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  };

  return date.toLocaleDateString(undefined, options);
}

export function formatMaritalStatus(status: string): string {
  return status
    .replace(/_/g, ' ')
    .replace(/\b\w/g, match => match.toUpperCase());
}

export function formatBirthDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function hasSpecialPlus(restrictions?: {
  postPhotoForbiddenTags: string[];
  postVideoForbiddenTags: string[];
}): boolean {
  if (!restrictions) return false;
  return !(
    restrictions.postPhotoForbiddenTags.includes('special_plus') ||
    restrictions.postVideoForbiddenTags.includes('special_plus')
  );
}

export function eqArrays<T>(x: T[], y: T[]) {
  if (x.length !== y.length) return false;
  return x.every(x => y.includes(x));
}
